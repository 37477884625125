import { Grid, Container } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import styles from "./HeaderBanner.module.css";

export default function HeaderBanner() {
  return (
    <Grid className={styles.container}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <p>
              If you have any question? <br />
              Call Us
              <a className={styles.hyperText} href="tel:+919746693296">
                {" "}
                +91 9746 693296
              </a>
            </p>
          </Grid>
          <Grid item>
            <div>
              <a target="blank" href="https://www.facebook.com/sanoft">
                <FacebookIcon className={styles.spacingSmall} />
              </a>
              <a
                target="blank"
                href="https://www.linkedin.com/company/sanoft-technologies"
              >
                <LinkedInIcon className={styles.spacingSmall} />
              </a>
              <a
                target="blank"
                href="https://www.youtube.com/channel/UCrv_xbdbgYqiQBKE2gKZjGg"
              >
                <YouTubeIcon className={styles.spacingSmall} />
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
