import { useContext } from "react";
import { Grid, Button, CircularProgress, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HeaderBanner from "./HeaderBanner";
import { UserContext } from "../contexts/UserContext";
import OrganizationHelper from "../helpers/OrganizationHelper";
import styles from "./Header.module.css";

export default function Header({ pageRequireAuth }) {
  const { isLoadingUserDetails, isLoggedIn } = useContext(UserContext);
  return (
    <>
      <HeaderBanner />
      <div className={styles.headerLayout}>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Link to="/user">
                <img src="/logo192.png" alt="logo" style={{ height: 30 }} />
              </Link>
            </Grid>
            <Grid item>
              {isLoadingUserDetails ? (
                <CircularProgress color="secondary" />
              ) : isLoggedIn() ? (
                <UserActions pageRequireAuth={pageRequireAuth} />
              ) : (
                <AnonymousActions />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const UserActions = ({ pageRequireAuth }) => {
  const { organizations, profile, initiateCreateOrganization, initiateLogout } =
    useContext(UserContext);
  const navigate = useNavigate();

  const onLogoutClicked = async () => {
    await initiateLogout();
    if (pageRequireAuth) {
      // TODO: Consider to save the page user first landed and open that on logout.
      // navigate(userFirstLandedPage || "/");
      navigate("/");
    }
  };

  const gotoConsoleClicked = () => {
    navigate("/user");
  };

  const organization = OrganizationHelper.getSelected(organizations);
  return (
    <Grid container direction="row">
      {organization ? (
        <>
          <div className={styles.orgDetails}>
            <div>
              {profile?.firstName}
              {profile?.lastName && ` ${profile?.lastName}`}
            </div>
            <div className={styles.textSmall}>{organization?.name}</div>
          </div>
          {!window.location.pathname.startsWith("/user") && (
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: 6 }}
              onClick={gotoConsoleClicked}
            >
              CONSOLE
            </Button>
          )}
        </>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          style={{ marginRight: 6 }}
          onClick={initiateCreateOrganization}
        >
          CREATE SHOP
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: 3, marginLeft: 3 }}
        onClick={onLogoutClicked}
      >
        LOGOUT
      </Button>
    </Grid>
  );
};

const AnonymousActions = () => {
  const { initiateLogin, initiateRegister } = useContext(UserContext);
  return (
    <>
      <Button
        style={{ backgroundColor: "#ED7733" }}
        variant="contained"
        onClick={initiateLogin}
        size="small"
      >
        Login
      </Button>
      <Button
        style={{ marginLeft: 6, backgroundColor: "#EBEBEB" }}
        variant="outlined"
        onClick={initiateRegister}
        size="small"
      >
        Sign Up
      </Button>
    </>
  );
};
