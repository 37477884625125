import { STORAGE_KEYS } from "../utils/constants";

const getSelected = (organizations) => {
  if (!organizations || organizations.length === 0) {
    return null;
  }

  const organizationId = localStorage.getItem(
    STORAGE_KEYS.SELECTED_ORGANIZATION_ID
  );

  if (organizationId) {
    const organization = organizations.find((org) => org.id === organizationId);
    if (organization) {
      return organization;
    }
  }

  localStorage.setItem(
    STORAGE_KEYS.SELECTED_ORGANIZATION_ID,
    organizations[0].id
  );
  return organizations[0];
};

const OrganizationHelper = { getSelected };
export default OrganizationHelper;
