import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import styles from "./PaymentStatus.module.css";

const serialiseSearchParams = (searchParams) => {
  const params = {};
  searchParams
    .toString()
    .split("&")
    .forEach((query) => {
      const queryValues = query.split("=");
      params[queryValues[0]] = queryValues[1];
    });
  return params;
};

export default function PaymentStatus() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { status, applied, error } = serialiseSearchParams(searchParams);

  return (
    <div className={styles.rootLayout}>
      <div className={styles.contentLayout}>
        <div className={styles.titleText}>
          Your payment is {status === "success" ? "successful" : "failed"}
        </div>

        {status === "success" ? (
          <DoneIcon sx={{ fontSize: 100, color: "#19cf19" }} />
        ) : (
          <ErrorIcon sx={{ fontSize: 100, color: "#cf1919" }} />
        )}

        <div className={styles.detailsText}>
          {status === "success"
            ? applied === "true"
              ? "Your licence has been created succesfully. Now you would be able to login to the register user console"
              : "We need more details to create your licence, please contact our customer service"
            : "Sorry, your payment failed to complete this time. If the amount debited from bank already, wait for two hours to get it reflected. Contact our customer service for any support."}
        </div>
        {error && (
          <div className={styles.errorText}>
            Error message: {decodeURI(error).replaceAll("+", " ")}
          </div>
        )}
        <div className={styles.actionButton}>
          <Button variant="contained" onClick={() => navigate("/user")}>
            GO HOME
          </Button>
        </div>
      </div>
    </div>
  );
}
