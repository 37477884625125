import { useEffect, useState, useContext } from "react";
import {
  Grid,
  Container,
  Card,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import ApiHelper from "../helpers/ApiHelper";
import Header from "../components/Header";
import PaymentDialog from "../dialogs/PaymentDialog";
import styles from "./LicencePlans.module.css";
import { useParams } from "react-router-dom";
import { AlertMessageContext } from "../contexts/AlertMessageContext";

export default function LicencePlans() {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [plans, setPlans] = useState(null);
  const [selectedPlan, setSelectedPlans] = useState(null);
  const { productId } = useParams();
  const { postErrorAlert } = useContext(AlertMessageContext);

  const openPaymentsPage = (plan) => {
    setSelectedPlans(plan);
  };

  useEffect(() => {
    async function loadPlans() {
      setShowBackdrop(true);
      try {
        const data = await ApiHelper.getSubscriptionPlans(productId);
        setPlans(data);
      } catch (error) {
        postErrorAlert(error.message);
      }
      setShowBackdrop(false);
    }

    loadPlans().then();
  }, [productId, postErrorAlert]);

  return (
    <>
      <div className={styles.rootLayout}>
        <Header />
        <Container>
          <div className={styles.contentsLayout}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3 className={styles.pageTitle}>Choose your plan</h3>
              </Grid>

              {plans &&
                plans.map((plan) => {
                  return (
                    <Grid item xs={12} md={6} key={plan.id}>
                      <Card
                        className={styles.planItem}
                        onClick={() => openPaymentsPage(plan)}
                      >
                        <div
                          style={{
                            padding: 10,
                            borderRadius: 5,
                            marginTop: 10,
                            width: "calc(100% - 20px)",
                            textAlign: "center",
                          }}
                        >
                          <h3 className={styles.planItemTitle}>
                            {plan.name}
                          </h3>
                          <div className={styles.planItemNormalText}>
                            {plan.description}
                          </div>
                          <h2 className={styles.planItemRate}>
                            ₹{plan.amount}
                          </h2>
                        </div>
                      </Card>
                    </Grid>
                  );
                })}
              <Backdrop open={showBackdrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </div>
        </Container>
      </div>

      {selectedPlan && (
        <PaymentDialog
          plan={selectedPlan}
          handleClose={() => {
            setSelectedPlans(null);
          }}
          setError={postErrorAlert}
        />
      )}
    </>
  );
}
