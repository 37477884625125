import { Container, Grid } from "@mui/material";
import {
  ChevronRight,
  Facebook,
  Instagram,
  LinkedIn,
  YouTube,
} from "@mui/icons-material";
import styles from "./Footer.module.css";
import { ROUTES } from "../utils/constants";

export default function Footer() {
  return (
    <div id="footer" className={styles.footer}>
      <Container>
        <Grid container className={styles.footerTop}>
          <Grid item md={6} lg={4} className={styles.footerInfo}>
            <a href="index.html" className={styles.logo}>
              <img
                src="./footerLogo.png"
                alt="
             The complete software and POS provider"
              />
            </a>
            <p>
              Sanoft Technologies Pvt. Ltd. is a software company whose primary
              products are various forms of software, software technology and
              software product development.Our software development services
              span across web software, cloud software, enterprise software, and
              mobile apps software applications.
            </p>
            <div className={styles.socialLinks}>
              <a href="https://www.facebook.com/sanoft" class="Facebook">
                <Facebook />
              </a>
              <a href="https://www.facebook.com/sanoft" class="Instagram">
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/company/sanoft-technologies"
                class="LinkedIn"
              >
                <LinkedIn />
              </a>
              <a
                href="https://www.youtube.com/channel/UCrv_xbdbgYqiQBKE2gKZjGg"
                class="Youtube"
              >
                <YouTube />
              </a>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={2} className={styles.footerLinks}>
            <h4>Useful Links</h4>
            <ul>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/#welcome">About us</a>
              </li>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/#contact">Contact Us</a>
              </li>
              <li>
                <ChevronRight />
                <a href={ROUTES.PRIVACY_POLICY}>Privacy Policy</a>
              </li>
              <li>
                <ChevronRight />
                <a href={ROUTES.TERMS_AND_CONDITION}>Terms & Conditions</a>
              </li>
              <li>
                <ChevronRight />
                <a href={ROUTES.REFUND_POLICY}>Return & Refund Policy</a>
              </li>
              <li>
                <ChevronRight />
                <a href={ROUTES.SHIPPING_POLICY}>Shipping Policy</a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} md={6} lg={3} className={styles.footerLinks}>
            <h4>Our Services</h4>
            <ul>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/">POS Software</a>
              </li>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/">POS Hardware</a>
              </li>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/">Web Designing</a>
              </li>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/">Graphic Designing</a>
              </li>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/">Software Development</a>
              </li>
              <li>
                <ChevronRight />
                <a href="https://www.sanoft.com/">App Development</a>
              </li>
            </ul>
          </Grid>
          <Grid item md={12} lg={3} className={styles.footerContact}>
            <h4>Contact Us</h4>
            <p style={{ marginTop: -15 }}>
              Sanoft Technologies Pvt Ltd.
              <br />
              KKM City Center, 2nd Floor,
              <br />
              Near CSB Bank, Areekode,
              <br />
              Kerala, India 673639 <br />
              <br />
              <strong>Mobile: </strong>
              <a href="tel:9746393923"> +91 9746 3939 23</a>
              <br />
              <strong>Phone: </strong>
              <a href="tel:04832955333"> 0483 29 55 333</a>
              <br />
              <strong>Email: </strong>
              <a href="mailto:hi@sanoft.com"> hi@sanoft.com</a>
            </p>
          </Grid>
        </Grid>
        <Grid>
          <div className={styles.copyright}>
            &copy; Copyright
            <strong>
              <span>
                <a href="https://www.sanoft.com/">
                  {" "}
                  Sanoft Technologies Pvt. Ltd.
                </a>
              </span>
            </strong>{" "}
            All Rights Reserved
          </div>
        </Grid>
      </Container>
    </div>
  );
}
