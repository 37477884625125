import axios from "axios";
import {
  BASE_API_URL,
  DEFAULT_ERROR_MESSAGE,
  STORAGE_KEYS,
} from "../utils/constants";

const login = async (credentials) => {
  const url = `${BASE_API_URL}/auth/login`;
  const response = await _request({
    url,
    method: "POST",
    data: credentials,
  });

  localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.accessToken);
  localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, response.refreshToken);

  return response;
};

const createUser = async (userData) => {
  const url = `${BASE_API_URL}/auth/register`;
  await _request({
    url,
    method: "POST",
    data: userData,
  });

  return login({
    username: userData.username,
    password: userData.password,
  });
};

const getOrganizations = async () => {
  const url = `${BASE_API_URL}/organizations`;
  return _request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: false,
  });
};

const getUserProfile = async () => {
  const url = `${BASE_API_URL}/auth/profile`;
  return _request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: false,
  });
};

const createOrganization = async (data) => {
  const url = `${BASE_API_URL}/organizations`;
  try {
    const response = await _request({
      url,
      method: "POST",
      data,
      requireAuth: true,
      requireOrg: false,
    });

    return { data: response };
  } catch (error) {
    const response = error?.response?.data || {};
    const message = response.message || DEFAULT_ERROR_MESSAGE;
    return { error: message };
  }
};

const getLicences = async () => {
  const url = `${BASE_API_URL}/licences`;
  return _request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: true,
  });
};

const initiatePayment = async (planId) => {
  const url = `${BASE_API_URL}/payments`;
  try {
    const response = await _request({
      url,
      method: "POST",
      data: {
        provider: "paytm",
        planId: planId,
      },
      requireAuth: true,
      requireOrg: true,
    });

    return { data: response };
  } catch (error) {
    const response = error?.response?.data || {};
    const message = response.message || DEFAULT_ERROR_MESSAGE;
    return { error: message };
  }
};

const getSubscriptionPlans = async (productId) => {
  const url = `${BASE_API_URL}/subscription-plans`;
  return _request({
    url,
    method: "GET",
    params: { product: productId },
    requireAuth: false,
    requireOrg: false,
  });
};

const getSubscriptionPlan = async (planId) => {
  const url = `${BASE_API_URL}/subscription-plans/${planId}`;
  return _request({
    url,
    method: "GET",
    requireAuth: false,
    requireOrg: false,
  });
};

const logout = async () => {
  if (!localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)) {
    return;
  }

  const url = `${BASE_API_URL}/auth/logout`;
  return _request({
    url,
    method: "DELETE",
    requireAuth: true,
  });
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  if (!refreshToken) {
    return;
  }

  const url = `${BASE_API_URL}/auth/login-by-refresh-token`;
  const response = await _request({
    url,
    method: "POST",
    data: { refreshToken },
  });
  localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.accessToken);
};

const _request = async (option, retry = 0) => {
  if (option.requireAuth && !localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)) {
    throw new Error(
      "Invalid API call, called API require auth without logging in"
    );
  }

  const selectedOrgId = localStorage.getItem(
    STORAGE_KEYS.SELECTED_ORGANIZATION_ID
  );
  if (option.requireOrg && !selectedOrgId) {
    throw new Error(
      "Invalid API call, called API require organization without selecting organization"
    );
  }

  option.headers = option.headers || {};
  if (option.requireAuth) {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      option.headers["Authorization"] = accessToken;
    }
  }

  if (option.requireOrg) {
    option.headers["organization-id"] = selectedOrgId;
  }

  try {
    const response = await axios(option);
    return response.data;
  } catch (error) {
    if (retry >= 2) {
      _throwHttpError(error);
    }
    const response = error?.response || {};
    const body = response.data || {};
    if (
      response.status === 401 &&
      body.code !== "ACCESS_TOKEN_EXPIRED" &&
      body.code !== "MISSING_AUTH_TOKEN"
    ) {
      localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
      console.error(error);
      window.location.reload();
      throw error;
    }

    if (
      body.code === "ACCESS_TOKEN_EXPIRED" ||
      body.code === "USER_HAS_NO_PERMISSION" ||
      body.code === "MISSING_AUTH_TOKEN"
    ) {
      localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
      await refreshAccessToken();
    }
    const resp = await _request(option, retry + 1);
    console.log({ resp, retry });
    return resp;
  }
};

const _throwHttpError = (error) => {
  const response = error?.response?.data || {};
  const message = response.message || DEFAULT_ERROR_MESSAGE;
  throw new Error(message);
};

const ApiHelper = {
  createUser,
  login,
  getUserProfile,
  getOrganizations,
  createOrganization,
  getLicences,
  initiatePayment,
  getSubscriptionPlans,
  getSubscriptionPlan,
  logout,
};

export default ApiHelper;
