import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Header from "../components/Header";

export default function DrawResult() {
  const navigate = useNavigate();
  const licence = useMemo(() => {
    return { name: "Dummy licence" };
  }, []);

  useEffect(() => {
    if (!licence) {
      navigate("/user");
    }
  }, [licence, navigate]);

  const goHome = () => {
    navigate("/user");
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          minHeight: 400,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1>Draw Result</h1>
          <h4>Result will publish here soon</h4>

          <div style={{ marginTop: 30 }}>
            <Button variant="contained" onClick={goHome}>
              GO HOME
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
