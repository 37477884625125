import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import ApiHelper from "../helpers/ApiHelper";
import { STORAGE_KEYS } from "../utils/constants";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./LoginDialog.module.css";

export default function LoginDialog({
  show,
  handleClose,
  onNavigateToRegister,
  onSuccessLogin,
}) {
  const [credentials, setCredentials] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loginInProgress, setLoginInProgress] = useState(false);

  const handleUserLogin = async () => {
    const success = validateInput();
    if (!success) {
      return;
    }

    setLoginInProgress(true);
    try {
      const response = await ApiHelper.login(credentials);
      onSuccessLogin(response);
    } catch (error) {
      setFormErrors({ password: { message: error.message } });
    }
    setLoginInProgress(false);
  };

  const validateInput = () => {
    const errors = {};
    if (!credentials.username) {
      errors.username = { message: "Username is mandatory" };
    }
    if (!credentials.password) {
      errors.password = { message: "Password is mandatory" };
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)) {
      handleClose();
    }
  }, [handleClose]);

  return (
    <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Login
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          error={Boolean(formErrors.username)}
          label="Username"
          helperText={formErrors.username?.message}
          fullWidth
          onChange={(event) => {
            setCredentials({
              ...credentials,
              username: event.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          error={Boolean(formErrors.password)}
          label="Password"
          type="password"
          helperText={formErrors.password?.message}
          fullWidth
          onChange={(event) => {
            setCredentials({
              ...credentials,
              password: event.target.value,
            });
          }}
        />

        <Grid container justifyContent="center" style={{ marginTop: 20 }}>
          <LoadingButton
            className={styles.loginButton}
            loading={loginInProgress}
            onClick={handleUserLogin}
            variant="contained"
          >
            Login
          </LoadingButton>
        </Grid>

        {/* <div className="row sub-w3l my-3">
              <div className="col forgot-w3l text-right text-dark">
                <a href="#" className="text-white">
                  Forgot Password?
                </a>
              </div>
            </div> */}

        <div className={styles.registerNavigationLayout}>
          Don't have an account?&nbsp;
          {/* eslint-disable-next-line */}
          <a
            className={styles.registerNavigation}
            onClick={onNavigateToRegister}
          >
            Register now
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
}
