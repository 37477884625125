import { Fab } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const DEFAULT_MESSAGE =
  "Hello,%0aI would like to know more about your products";
const SUPPORT_WHATSAPP = "919746393923";

export default function ContactButton() {
  return (
    <Fab
      color="success"
      variant="extended"
      style={{ position: "fixed", bottom: 20, right: 20 }}
      onClick={() => {
        window.open(
          `https://api.whatsapp.com/send?phone=${SUPPORT_WHATSAPP}&text=${DEFAULT_MESSAGE}`,
          "_blank"
        );
      }}
    >
      <WhatsAppIcon />
      &nbsp;Contact
    </Fab>
  );
}
