import Footer from "../components/Footer";
import Header from "../components/Header";
import { Container, Grid } from "@mui/material";
import styles from "./policy.module.css";

export default function ShippingPolicy() {
  return (
    <>
      <Header />
      <Container>
        <Grid className={styles.policyGrid}>
          <h2>Shipping Policy</h2>
          <p>
            SANOFT TECHNOLOGIES PRIVATE LIMITED believes in helping its
            customers as far as possible, and has therefore a liberal shipping
            policy. Under this policy:
          </p>
          <ul>
            <li>
              Shipping Methods: Specify the shipping carriers and methods you
              offer, such as standard ground shipping, expedited shipping, or
              international shipping. Include any limitations or restrictions
              that may apply.
            </li>
            <li>
              Processing Time: State the time it takes for you to process and
              prepare orders for shipping. This may include order verification,
              packaging, and handling. Provide an estimated processing time,
              such as 1-3 business days.
            </li>
            <li>
              Shipping Timeframes: Inform customers about the estimated delivery
              timeframes once the order has been shipped. This may vary based on
              the shipping method chosen by the customer and their location
            </li>
            <li>
              Shipping Costs: Clearly communicate how shipping costs are
              calculated. You can specify whether you offer free shipping,
              charge a flat rate, or calculate costs based on factors like order
              weight, destination, or order value. If applicable, mention any
              additional fees or customs charges for international shipments.
            </li>
            <li>
              Order Tracking: Explain how customers can track their orders,
              including providing tracking numbers or links to tracking pages.
            </li>
            <li>
              Shipping Restrictions: Outline any restrictions or limitations on
              shipping, such as specific countries or regions where you do not
              ship, or products that are restricted from being shipped due to
              legal or safety reasons.
            </li>
            <li>
              Damaged or Lost Shipments: Clarify your policy regarding damaged
              or lost shipments. Provide instructions for customers to follow if
              they receive damaged products or if their package goes missing
              during transit.
            </li>
            <li>
              Returns and Exchanges: If applicable, include information about
              your return and exchange policy for products that have already
              been shipped. Specify any conditions or requirements for returns,
              such as time limits, return shipping fees, or restocking fees.
            </li>
            <li>
              Customer Support: Provide contact information or a link to your
              customer support page for any shipping-related inquiries or
              concerns.
            </li>
            <li>
              Legal Disclaimers: Include any necessary legal disclaimers related
              to shipping, such as limitations of liability, delays due to
              unforeseen circumstances, or changes to the shipping policy.
            </li>
          </ul>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}
