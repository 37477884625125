import {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import ApiHelper from "../helpers/ApiHelper";
import { STORAGE_KEYS } from "../utils/constants";
import { AlertMessageContext } from "./AlertMessageContext";
import RegisterUserDialog from "../dialogs/RegisterUserDialog";
import LoginDialog from "../dialogs/LoginDialog";
import CreateOrganizationDialog from "../dialogs/CreateOrganizationDialog";

export const UserContext = createContext({});

export default function UserProvider({ children }) {
  const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(false);
  const [profile, setProfile] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openRegisterUserDialog, setOpenRegisterUserDialog] = useState(false);
  const [openCreateOrgDialog, setOpenCreateOrgDialog] = useState(false);
  const { postErrorAlert } = useContext(AlertMessageContext);

  const initiateLogin = () => {
    setOpenLoginDialog(true);
  };

  const initiateRegister = () => {
    setOpenRegisterUserDialog(true);
  };

  const initiateCreateOrganization = () => {
    setOpenCreateOrgDialog(true);
  };

  const initiateLogout = async () => {
    setIsLoadingUserDetails(true);
    try {
      await ApiHelper.logout();
    } catch (error) {}
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
    setProfile(null);
    setOrganizations(null);
    setIsLoadingUserDetails(false);
  };

  const isLoggedIn = () => {
    return Boolean(localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN));
  };

  const getProfile = useCallback(async () => {
    if (!isLoggedIn()) {
      setProfile(null);
      return;
    }

    try {
      const profile = await ApiHelper.getUserProfile();
      setProfile(profile);
    } catch (error) {
      postErrorAlert(error.message);
    }
  }, [setProfile, postErrorAlert]);

  const loadOrganizations = useCallback(async () => {
    if (!isLoggedIn()) {
      setOrganizations(null);
      return;
    }

    try {
      const organizations = await ApiHelper.getOrganizations();
      setOrganizations(organizations);

      // Auto selecting first organization as we support only one organization for one user now
      if (organizations[0]) {
        localStorage.setItem(
          STORAGE_KEYS.SELECTED_ORGANIZATION_ID,
          organizations[0].id
        );
      }
    } catch (error) {
      setOrganizations(null);
      postErrorAlert(error.message());
    }
  }, [postErrorAlert]);

  useEffect(() => {
    async function loadInitialDetails() {
      setIsLoadingUserDetails(true);
      await Promise.all([getProfile(), loadOrganizations()]);
      setIsLoadingUserDetails(false);
    }
    loadInitialDetails().then();
  }, [getProfile, loadOrganizations]);

  return (
    <UserContext.Provider
      value={{
        profile,
        organizations,
        isLoadingUserDetails,
        initiateLogin,
        initiateRegister,
        initiateCreateOrganization,
        initiateLogout,
        isLoggedIn,
      }}
    >
      {children}
      <UserManagement
        openRegisterUserDialog={openRegisterUserDialog}
        setOpenRegisterUserDialog={setOpenRegisterUserDialog}
        openLoginDialog={openLoginDialog}
        setOpenLoginDialog={setOpenLoginDialog}
        openCreateOrgDialog={openCreateOrgDialog}
        setOpenCreateOrgDialog={setOpenCreateOrgDialog}
        setProfile={setProfile}
        setOrganizations={setOrganizations}
        loadOrganizations={loadOrganizations}
      />
    </UserContext.Provider>
  );
}

function UserManagement({
  openRegisterUserDialog,
  setOpenRegisterUserDialog,
  openLoginDialog,
  setOpenLoginDialog,
  openCreateOrgDialog,
  setOpenCreateOrgDialog,
  setProfile,
  setOrganizations,
  loadOrganizations,
}) {
  const handleSuccessfulLogin = (response) => {
    const organizations = response.user?.organizations || [];

    setOrganizations(organizations);
    setProfile(response.user);

    setOpenLoginDialog(false);
    setOpenRegisterUserDialog(false);

    if (organizations.length === 0) {
      setOpenCreateOrgDialog(true);
      return;
    }
    // Load organizations full details
    loadOrganizations();
  };

  return (
    <>
      {openRegisterUserDialog && (
        <RegisterUserDialog
          show={openRegisterUserDialog}
          handleClose={() => {
            setOpenRegisterUserDialog(false);
          }}
          onNavigateToLogin={() => {
            setOpenRegisterUserDialog(false);
            setOpenLoginDialog(true);
          }}
          onSuccessRegister={handleSuccessfulLogin}
        />
      )}

      {openLoginDialog && (
        <LoginDialog
          show={openLoginDialog}
          handleClose={() => {
            setOpenLoginDialog(false);
          }}
          onNavigateToRegister={() => {
            setOpenRegisterUserDialog(true);
            setOpenLoginDialog(false);
          }}
          onSuccessLogin={handleSuccessfulLogin}
        />
      )}

      {openCreateOrgDialog && (
        <CreateOrganizationDialog
          show={openCreateOrgDialog}
          handleClose={() => {
            setOpenCreateOrgDialog(false);
          }}
          onSuccess={(organization) => {
            setOrganizations([organization]);
            setOpenCreateOrgDialog(false);
          }}
        />
      )}
    </>
  );
}
