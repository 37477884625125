import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Container,
} from "@mui/material";
import { PRODUCTS, STORAGE_KEYS } from "../utils/constants";
import ApiHelper from "../helpers/ApiHelper";
import Header from "../components/Header";
import styles from "./UserHome.module.css";
import { AlertMessageContext } from "../contexts/AlertMessageContext";
import { UserContext } from "../contexts/UserContext";

export default function UserHome() {
  const navigate = useNavigate();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [licences, setLicences] = useState(null);
  const { organizations } = useContext(UserContext);
  const { postErrorAlert } = useContext(AlertMessageContext);

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (organizations == null) {
      return;
    }
    if (organizations.length === 0) {
      navigate("/");
      return;
    }

    async function getLicences() {
      setShowBackdrop(true);
      try {
        const _licences = await ApiHelper.getLicences();
        setLicences(_licences ?? null);
      } catch (error) {
        postErrorAlert(error);
      }
      setShowBackdrop(false);
    }

    getLicences().then();
  }, [organizations, navigate, postErrorAlert, setLicences]);

  const onClickGotoPlans = () => {
    navigate("/");
  };

  if (organizations == null || organizations.length === 0 || showBackdrop) {
    return (
      <Backdrop open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div className={styles.rootLayout}>
      <Header />
      <Container>
        <div className={styles.contentsLayout}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                {licences === null ? (
                  <div>Loading</div>
                ) : (
                  <CardContent>
                    <h3 className={styles.pageTitle}>Licence plan</h3>
                    {getLicencesDetailsSection(licences)}
                    <div className={styles.contentSpacing}>
                      <Button
                        color="info"
                        onClick={onClickGotoPlans}
                        variant="contained"
                      >
                        BROWS PLANS
                      </Button>
                    </div>
                  </CardContent>
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

function getLicencesDetailsSection(licences) {
  if (licences.length === 0) {
    return (
      <>
        <div className={styles.contentSpacing}>
          <p>
            You don't have any valid active plan now. Click below go to plans
            buttons to purchase a new plan.
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.contentSpacing}>
        Your have valid licence plans. Your plan details are provided below.
      </div>

      <div className={styles.contentSpaceExtra}>
        {licences.map((licence) => (
          <div className={styles.contentSpaceExtra}>
            <Grid key={licence.id} container spacing={2}>
              <Grid item xs={12} className={styles.licenceActiveText}>
                {PRODUCTS[licence.plan.product]?.name ?? "-"} licence
              </Grid>
              <Grid item xs={5}>
                Plan
              </Grid>
              <Grid item xs={7} className={styles.licenceActiveText}>
                {licence.plan.name}
              </Grid>
              <Grid item xs={5}>
                Purchased on
              </Grid>
              <Grid item xs={7} className={styles.licenceActiveText}>
                {new Date(licence.createdAt).toLocaleDateString()}
              </Grid>
              <Grid item xs={5}>
                Expiry date
              </Grid>
              <Grid item xs={7} className={styles.licenceActiveText}>
                {new Date(licence.expireAt).toLocaleDateString()}
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
      <div className={styles.contentSpaceExtra}>
        <p>
          If you wanted to further explore our other products and plans, please
          click the below button.
        </p>
      </div>
    </>
  );
}
