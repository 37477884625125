import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./PublicHome.module.css";
import { Card, Link, Container, Grid } from "@mui/material";

export default function PublicHome({
  setOpenRegisterUserDialog,
  setOpenLoginDialog,
  setOpenCreateOrgDialog,
}) {
  return (
    <>
      <Header
        onOpenRegisterForm={() => {
          setOpenRegisterUserDialog(true);
        }}
        onOpenLoginForm={() => {
          setOpenLoginDialog(true);
        }}
        onOpenCreateOrgForm={() => {
          setOpenCreateOrgDialog(true);
        }}
      />
      <Container className={styles.mainSection}>
        <h2 className={styles.textTitle}>SANOFT PRODUCTS</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={styles.ProductCard}>
              <Link href="/plans/sanoft-pos">
                <h3>Sanoft POS</h3>
                <p>
                  Sanoft POS is a web and Android based POS application having
                  support for customer ordering KIOSK, online module for
                  customers to brows through the menu and order from, and QR
                  code based smart menu
                </p>
                <button type="info" className={styles.exploreBtn}>
                  View More
                </button>
              </Link>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={styles.ProductCard}>
              <Link href="/plans/bflexpos">
                <h3>BFlexPOS</h3>
                <p>
                  BFlexPOS is a web based simple basic POS application from
                  Sanoft. BflexPOS supports all the basic POS features like
                  inventory management, bar code support, and basic sales
                  reports.
                </p>
                <button type="info" className={styles.exploreBtn}>
                  View More
                </button>
              </Link>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={styles.ProductCard}>
              <Link href="/plans/eazy-menu">
                <h3>Eazy Menu</h3>
                <p>
                  Eazy Menu from Sanoft is an online based restaurant menu.
                  Customers can access the menu either by opening a pre shared
                  URL or by scanning the QR code that can be placed on the table
                  or premises.
                </p>
                <button type="info" className={styles.exploreBtn}>
                  View More
                </button>
              </Link>
            </Card>
          </Grid>
        </Grid>
        {/*<Card className={styles.spacing}>*/}
        {/*  <Link href="/plans/eazy-park">Eazy Park</Link>*/}
        {/*</Card>*/}
      </Container>
      <Footer />
    </>
  );
}
