import { createContext, useState } from "react";
import { Alert, Stack } from "@mui/material";
import { nanoid } from "nanoid";

export const AlertMessageContext = createContext({});

export default function AlertMessageProvider({ children }) {
  const [alerts, setAlerts] = useState([]);

  const postAlert = (alert) => {
    setAlerts((alerts) => {
      alert.key = alert.key || nanoid();
      setTimeout(() => {
        setAlerts((alerts) => {
          return alerts.filter((m) => m.key !== alert.key);
        });
      }, 2000);
      return [...alerts, alert];
    });
  };

  const postSuccessAlert = (message) => {
    postAlert({ type: "success", message });
  };

  const postErrorAlert = (message) => {
    postAlert({ type: "error", message });
  };

  return (
    <AlertMessageContext.Provider
      value={{ postAlert, postSuccessAlert, postErrorAlert }}
    >
      <AlertMessages alerts={alerts} />
      {children}
    </AlertMessageContext.Provider>
  );
}

function AlertMessages({ alerts }) {
  return (
    <Stack
      spacing={2}
      style={{ padding: 10, position: "fixed", right: 0, zIndex: 99999 }}
    >
      {alerts.map((alert) => {
        return (
          <Alert key={alert.key} severity={alert.type}>
            {alert.message}
          </Alert>
        );
      })}
    </Stack>
  );
}
