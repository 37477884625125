import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicHomePage from "./pages/PublicHome";
import TermsAndConditionsPage from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundReturnPolicy from "./pages/RefundReturnPolicy";
import ShippingPolicy from "./pages/ShippingPolicy";
import PaymentStatusPage from "./pages/PaymentStatus";
import UserHome from "./pages/UserHome";
import UserPayment from "./pages/UserPayment";
import DrawResult from "./pages/DrawResult";
import LicencePlansPage from "./pages/LicencePlans";
import NotFound from "./pages/NotFound";
import AlertMessageProvider from "./contexts/AlertMessageContext";
import UserProvider from "./contexts/UserContext";
import ContactButton from "./components/ContactButton";
import { ROUTES } from "./utils/constants";

function App() {
  return (
    <BrowserRouter>
      <AlertMessageProvider>
        <UserProvider>
          <Routes>
            <Route path="/">
              <Route index element={<PublicHomePage />} />
              <Route path={ROUTES.TERMS_AND_CONDITION}element={<TermsAndConditionsPage />}/>
              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={ROUTES.REFUND_POLICY} element={<RefundReturnPolicy />} />
              <Route path={ROUTES.SHIPPING_POLICY} element={<ShippingPolicy />} />
              <Route path={ROUTES.LICENCE_PLANS} element={<LicencePlansPage />} />
              <Route path="user">
                <Route index element={<UserHome />} />
                <Route path="plans" element={<LicencePlansPage />} />
                <Route path="plans/:planId/payment" element={<UserPayment />} />
                <Route path="payment/status" element={<PaymentStatusPage />} />
                <Route path="draw-result" element={<DrawResult />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ContactButton />
        </UserProvider>
      </AlertMessageProvider>
    </BrowserRouter>
  );
}

export default App;
