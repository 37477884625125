import { useRef, useState, useContext } from "react";
import {
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ApiHelper from "../helpers/ApiHelper";
import styles from "./PaymentDialog.module.css";
import { UserContext } from "../contexts/UserContext";
import OrganizationHelper from "../helpers/OrganizationHelper";

export default function PaymentDialog({ setError, plan, handleClose }) {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const paymentFormRef = useRef();
  const {
    organizations,
    initiateLogin,
    initiateCreateOrganization,
    isLoggedIn,
  } = useContext(UserContext);

  const initiatePayment = async () => {
    setShowBackdrop(true);
    const paymentResponse = await ApiHelper.initiatePayment(plan.id);
    setShowBackdrop(false);
    if (paymentResponse.error) {
      setError("Error initiating payment");
      return;
    }

    setPaymentDetails(paymentResponse.data);
    setTimeout(() => {
      paymentFormRef.current?.submit();
    }, 0);
  };

  const organization = OrganizationHelper.getSelected(organizations);
  return (
    <>
      <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Confirm payment
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <List>
              <ListItem>
                <div className={styles.detailItemName}>Plan</div>
                <div className={styles.detailItemValue}>{plan.name}</div>
              </ListItem>
              <ListItem>
                <div className={styles.detailItemName}>Amount</div>
                <div className={styles.detailItemValue}>₹{plan.amount}</div>
              </ListItem>
              <ListItem>
                <div className={styles.detailItemName}>Description</div>
                <div className={styles.detailItemValue}>{plan.description}</div>
              </ListItem>
              {organization && (
                <ListItem>
                  <div className={styles.detailItemName}>Buying for</div>
                  <div className={styles.detailItemValue}>
                    {organization.name}
                  </div>
                </ListItem>
              )}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isLoggedIn() ? (
            <Button variant="contained" onClick={initiateLogin}>
              Login to proceed
            </Button>
          ) : !organization ? (
            <Button variant="contained" onClick={initiateCreateOrganization}>
              Create shop to proceed
            </Button>
          ) : (
            <Button variant="contained" onClick={initiatePayment}>
              Proceed to payment
            </Button>
          )}
        </DialogActions>

        <Backdrop open={showBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      {paymentDetails && (
        <form
          ref={paymentFormRef}
          method="post"
          action={paymentDetails.redirectUrl}
          name="paytm"
        >
          <input
            type="hidden"
            name="mid"
            value={paymentDetails.providerMerchantId || ""}
          />
          <input type="hidden" name="orderId" value={paymentDetails.id || ""} />
          <input
            type="hidden"
            name="txnToken"
            value={paymentDetails.providerToken || ""}
          />
        </form>
      )}
    </>
  );
}
