export const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  "https://sanoft-mini-erp.uc.r.appspot.com/api";

export const DEFAULT_ERROR_MESSAGE =
  "Oops, Something went wrong. If this persists, please contact us.";

export const STORAGE_KEYS = {
  REFRESH_TOKEN: "refresh-token",
  ACCESS_TOKEN: "access-token",
  SELECTED_ORGANIZATION_ID: "selected-organization",
};

export const PRODUCTS = {
  bflexpos: { name: "Bflex POS" },
  "sanoft-pos": { name: "Sanoft POS" },
  "eazy-menu": { name: "Eazy Menu" },
  "eazy-directory": { name: "Eazy Directory" },
};
export const ROUTES = {
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-and-conditions",
  REFUND_POLICY: "/refund-policy",
  SHIPPING_POLICY: "/shipping-policy",
  LICENCE_PLANS: "/plans/:productId",
  
};
