import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import ApiHelper from "../helpers/ApiHelper";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./LoginDialog.module.css";

export default function RegisterUserDialog({
  show,
  handleClose,
  onNavigateToLogin,
  onSuccessRegister,
}) {
  const [userData, setUserData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [registerInProgress, setRegisterInProgress] = useState(false);

  const handleUserRegister = async () => {
    const success = validateInput();
    if (!success) {
      return;
    }

    setRegisterInProgress(true);
    try {
      const response = await ApiHelper.createUser(userData);
      onSuccessRegister(response);
    } catch (error) {
      setFormErrors({ password: { message: error.message } });
    }
    setRegisterInProgress(false);
  };

  const validateInput = () => {
    const errors = {};
    if (!userData.firstName) {
      errors.firstName = { message: "First name is mandatory" };
    }
    if (!userData.lastName) {
      errors.lastName = { message: "Last name is mandatory" };
    }
    if (!userData.mobile) {
      errors.mobile = { message: "Mobile number is mandatory" };
    }
    if (!userData.username) {
      errors.username = { message: "Email is mandatory" };
    }
    if (!userData.password) {
      errors.username = { message: "Password is mandatory" };
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Register
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <TextField
          margin="normal"
          error={Boolean(formErrors.firstName)}
          label="First Name"
          helperText={formErrors.firstName?.message}
          fullWidth
          onChange={(event) => {
            setUserData({
              ...userData,
              firstName: event.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          error={Boolean(formErrors.lastName)}
          label="Last Name"
          helperText={formErrors.lastName?.message}
          fullWidth
          onChange={(event) => {
            setUserData({
              ...userData,
              lastName: event.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          error={Boolean(formErrors.username)}
          label="Email"
          type="email"
          helperText={formErrors.username?.message}
          fullWidth
          onChange={(event) => {
            setUserData({
              ...userData,
              username: event.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          error={Boolean(formErrors.mobile)}
          label="Mobile"
          type="number"
          helperText={formErrors.mobile?.message}
          fullWidth
          onChange={(event) => {
            setUserData({
              ...userData,
              mobile: event.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          error={Boolean(formErrors.password)}
          label="Password"
          type="password"
          helperText={formErrors.password?.message}
          fullWidth
          onChange={(event) => {
            setUserData({
              ...userData,
              password: event.target.value,
            });
          }}
        />

        <Grid container justifyContent="center" style={{ marginTop: 20 }}>
          <LoadingButton
            className={styles.loginButton}
            loading={registerInProgress}
            onClick={handleUserRegister}
            variant="contained"
          >
            Register
          </LoadingButton>
        </Grid>

        <div className={styles.registerNavigationLayout}>
          Have an account already?&nbsp;
          {/* eslint-disable-next-line */}
          <a className={styles.registerNavigation} onClick={onNavigateToLogin}>
            Login now
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
}
