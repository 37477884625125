import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import { Grid, Card, CircularProgress } from "@mui/material";
import ApiHelper from "../helpers/ApiHelper";
import { UserContext } from "../contexts/UserContext";
import Header from "../components/Header";
import styles from "./UserPayment.module.css";
import { AlertMessageContext } from "../contexts/AlertMessageContext";

export default function UserPayment() {
  const { organizations } = useContext(UserContext);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [plan, setPlan] = useState(null);
  const { postErrorAlert } = useContext(AlertMessageContext);
  const navigate = useNavigate();
  const { planId } = useParams();

  useEffect(() => {
    if (!organizations || organizations.length === 0) {
      navigate("/user");
    }
  }, [organizations, navigate]);

  useEffect(() => {
    async function loadPlan() {
      setShowBackdrop(true);
      try {
        const plan = await ApiHelper.getSubscriptionPlan(planId);
        setPlan(plan);
      } catch (error) {
        postErrorAlert(error);
      }
      setShowBackdrop(false);
    }
    loadPlan().then();
  }, [planId, postErrorAlert]);

  return (
    <>
      <div className={styles.rootLayout}>
        <Header />
        <div className={styles.contentsLayout}>
          <Card>
            <div style={{ margin: 20 }}>
              <Grid container spacing={2} className={styles.contentsPanel}>
                <div>Plan ID: {plan.id}</div>
                <div>Plan name: {plan.name}</div>
                INITIATE PAYMENT COMPONENT GOES HERE
              </Grid>
            </div>
          </Card>
        </div>
      </div>

      <Backdrop open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
